<template>
  <div class="app-mall-goods-detail container">
    <div class="content padding-20-0 h s">
      <div class="flex">
        <template v-if="detail">
          <div ref="base" class="h s" style="padding-bottom: 20px;">
            <div>
              <div class="thumbnail bc-l" :style="mainThumbnail"></div>
              <div class="thumbnail-list h wrap">
                <div class="item" v-for="(img, i) in images" :key="img.id" :class="{selected: imageIndex === i}" :style="{backgroundImage: `url(${img.url}?x-oss-process=image/resize,l_500)`}" @click="imageIndex = i"></div>
              </div>
            </div>
            <div class="flex">
              <div class="fs-huge name">{{detail.name}}</div>
              <el-alert type="error" :title="detail.remindWords" v-if="detail.remindWords" :closable="false" :show-icon="false" center style="margin-bottom: 10px;" />
              <template v-if="sku">
                <div class="padding-10-0" v-if="sku.code">商品编码：{{sku.code}}</div>
                <div class="h c">
                  <div>
                    <div class="fc-g item-name">单价</div>

                    <div v-if="priceInfo.loading" class="fc-g price-placeholder">正在获取中…</div>
                    <div v-else-if="priceInfo.error" class="h c price-placeholder">
                      <span class="fc-e">{{priceInfo.error}}</span>
                      <a href="javascript:void(0)" @click="loadGoodsPrice(scope.row)">
                        &nbsp;
                        <i class="el-icon-refresh"></i>
                      </a>
                    </div>
                    <div class="v e" style="line-height: 1.5" v-else>
                      <b class="price fc-e">{{$price(priceInfo.value, null, null, null, "")}}</b>
                      <span class="fc-g through" v-if="priceInfo.oldValue > priceInfo.value">{{$price(priceInfo.oldValue)}}</span>
                    </div>
                  </div>
                  <div class="flex"></div>
                  <!-- <div class="v c">
                    <div class="fc-g item-name">销量</div>
                    <div class="fs-large">21</div>
                  </div>-->
                </div>
                <template v-if="groupSales && groupSales.length">
                  <div class="sep"></div>
                  <div class="fc-g item-name">组合销售</div>
                  <div v-for="g in groupSales" :key="g.id" class="h c padding-05-0">
                    <el-tag type="danger">{{discountMethods[g.method]}}</el-tag>
                    <div class="padding-0-10">
                      <router-link class="linker" :to="buildGroupSalePath(g)">{{g.promotionName}}，{{g.name}}&emsp;&gt;&gt;</router-link>
                    </div>
                  </div>
                </template>

                <template v-if="groupSales && groupSales.length">
                  <div class="sep"></div>
                  <div class="fc-g item-name">促销活动</div>
                  <div v-for="p in promotions" :key="p.id" class="h c padding-05-0">
                    <el-tag type="danger">{{discountMethods[p.method]}}</el-tag>
                    <div class="padding-0-10 flex">
                      <a class="linker" href="javascript:void(0)" @click="handlePromotionDetail(p)">{{buildPronotionInfo(p)}}&emsp;&gt;&gt;</a>
                    </div>
                  </div>
                </template>

                <!-- <el-table :data="skus" highlight-current-row>
                <el-table-column label="规格" :formatter="r => { return $goodsSpecConvert(r.specs); }" />
                <el-table-column label="单价" align="right" width="120" :formatter="r => { return '￥' + (r.price / 100).toFixed(2); }" />
                <el-table-column label="数量" width="110">
                  <template slot-scope="scope">
                    <el-input-number size="mini" v-model="scope.row.num" controls-position="right" :min="scope.row.minSalesQuantity" :max="9999" :step="1" :precision="0" style="width: 100%;" />
                  </template>
                </el-table-column>
                </el-table>-->
                <div class="sep"></div>
                <div class="spec-row v" v-for="(s, si) in specArray" :key="s.name">
                  <div class="fc-g item-name">{{s.name}}</div>
                  <div class="flex h wrap">
                    <template v-for="(v, vi) in s.values">
                      <div class="spec-item" :key="v.name" :class="{disabled: !v.count, selected: vi === s.current}" @click="selectSpec(si, vi)" v-show="s.expand || vi < expandNums">{{v.name}}</div>
                    </template>
                    <template v-if="s.values.length >= expandNums">
                      <el-tooltip :content="s.expand ? '收起' : '更多'" placement="top">
                        <div class="spec-item" :class="s.expand ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" @click="s.expand = !s.expand"></div>
                      </el-tooltip>
                    </template>
                  </div>
                </div>
                <div class="sep"></div>

                <template v-if="!buyEnable && detail.purchaseLimit">
                  <div class="padding-20-0 fc-e">已限制采购</div>
                </template>
                <div class="h e" v-else>
                  <div style="margin-right: 10px;">
                    <div class="fc-g item-name">数量（起订量：{{sku.minSalesQuantity}}）</div>
                    <el-input-number v-model="nums" :min="sku.minSalesQuantity" :max="9999" :step="1" :precision="0" controls-position="right" step-strictly @change="handleNumsChange" style="width: 120px; margin-top: 5px;" />
                  </div>
                  <el-button type="danger" @click="handleCartAdd">加入购物车</el-button>
                  <el-button type="success" @click="handleBuy">立即购买</el-button>
                </div>
              </template>
              <template v-else-if="!skuLoading">
                <div class="padding-20-0 fc-e">当前供应商没有销售此商品</div>
              </template>
            </div>
          </div>
          <div class="infos">
            <div ref="infoTab" class="info-tab" :class="{fixed: fixTab}">
              <div class="inner h">
                <div class="item" :class="{selected: infoTab === 'intro'}" @click="infoTab = 'intro'">商品详情</div>
                <div class="item" :class="{selected: infoTab === 'bom'}" @click="infoTab = 'bom'">包装清单</div>
              </div>
            </div>
            <div v-show="infoTab === 'intro'">
              <div class="properties h wrap">
                <div v-for="p in properties" :key="p.id" class="item">{{p.name}}:&nbsp;{{p.value}}</div>
              </div>
              <div class="v" v-html="detail.info"></div>
            </div>
            <div v-show="infoTab === 'bom'">
              <el-table :data="boms" empty-text="该商品当前规格没有包装清单" style="margin-top: 10px;">
                <el-table-column label="名称" prop="bomSku.name" min-width="300" />
                <el-table-column label="规格" min-width="150">
                  <template slot-scope="scope">{{$goodsSpecConvert(scope.row.bomSku.specs)}}</template>
                </el-table-column>
                <el-table-column label="数量" prop="num" align="right" width="80" />
              </el-table>
            </div>
          </div>
        </template>
      </div>
      <div class="right-column">
        <history :supplier="supplier" :exclude="detail ? detail.id : null" />
      </div>
    </div>

    <el-dialog :visible.sync="promotion.show" title="促销详情" append-to-body width="800px">
      <promotion-detail :supplier="supplier" :promotion="promotion.current" :pickable="false" />
    </el-dialog>
  </div>
</template>

<script>
import History from "../assembly/history";
import promotionDetail from "../../assembly/promotionDetail";
import { getMallSkus, getMallSpu, getProperty, getBom } from "@/api/goods";
import { getPurchaseGoodsPrice } from "@/api/purchaseOrder";
import request from "@/utils/request";

export default {
  components: { History, promotionDetail },
  props: {
    supplier: String | Number,
  },
  watch: {
    supplier: "loadSkus",
  },
  data() {
    return {
      spuId: null,
      detail: null,
      images: [],
      imageIndex: 0,
      fixTab: false,
      infoTab: "intro",
      loading: false,
      skuLoading: false,
      sku: null,
      skus: [],
      skuIds: [],
      properties: [],
      boms: [],
      groupSales: [],
      promotions: [],
      promotion: {
        show: false,
        current: null,
      },
      isMallPromotionProduct: false,
      buyEnable: false,
      nums: 1,
      expandNums: 5,
      specArray: [],
      specObect: [],
      specSelectIndex: [],
      priceInfo: {
        loading: false,
        error: false,
        oldValue: null,
        value: null,
      },
      discountMethods: {
        discount: "打折",
        price: "一口价",
        save: "减价",
        gift: "赠品",
      },
    };
  },
  computed: {
    total() {
      let r = { nums: 0, price: 0 };
      this.skus.forEach((s) => {
        r.nums += s.num;
        r.price += s.num * s.price;
      });
      return r;
    },
    mainThumbnail() {
      if (
        this.images &&
        this.images.length &&
        this.imageIndex >= 0 &&
        this.imageIndex < this.images.length
      ) {
        return { backgroundImage: `url(${this.images[this.imageIndex].url})` };
      } else {
        return {};
      }
    },
  },
  methods: {
    onScroll(scrollTop, excludeHeight) {
      if (this.$refs.base) {
        this.fixTab =
          scrollTop >= this.$refs.base.offsetHeight + excludeHeight + 20;
      }
    },
    buildGroupSalePath(g) {
      return "/mall/group/item/" + btoa(g.id);
    },
    handlePromotionDetail(p) {
      this.promotion.current = p;
      this.promotion.show = true;
    },
    buildPronotionInfo(p) {
      let s = p.name + "：" + p.info;
      if (s.length > 30) s = s.substr(0, 30) + "…";
      return s;
    },
    loadDetail() {
      this.loading = true;
      getMallSpu(this.spuId, this.supplier)
        .then((res) => {
          if (res.coverImg) {
            this.images.push({
              url: res.coverImg + "?x-oss-process=image/resize,w_720,m_lfit",
            });
          }
          res.info = (res.info || "").replace(
            /\<img\ssrc=['"]([^'"]+)['"]/gi,
            function (a, b) {
              return `<img src="${b}?x-oss-process=image/resize,w_1080,m_lfit"`;
            }
          );
          this.detail = res;
          this.loadSkus();
        })
        .finally((_) => {
          this.loading = false;
        });
    },
    loadImages() {
      request({
        url: "api/file/search",
        method: "get",
        params: {
          page: 0,
          size: 1000,
          entityId: this.detail.fileId,
          sort: "displayNo,asc",
          folder: "goods/details",
        },
      }).then((res) => {
        (res.content || []).forEach((o) => {
          o.url = o.url + "?x-oss-process=image/resize,w_720,m_lfit";
        });
        this.images = this.images.concat(res.content);
      });
    },
    loadProperty() {
      getProperty(this.spuId).then((res) => {
        this.properties = res;
      });
    },
    loadSkus() {
      this.specSelectIndex = [];
      this.sku = null;
      this.skuLoading = true;
      getMallSkus(this.spuId, this.supplier)
        .then((res) => {
          let specArr = [],
            specObj = {}, skuIds = [];
          res
            .sort((a) => {
              return a.routine ? -1 : 1;
            })
            .forEach((ro) => {
              try {
                // if (ro.prices && ro.prices.length > 0) {
                if (ro.coverImg) {
                  this.images.push({
                    url:
                      ro.coverImg + "?x-oss-process=image/resize,w_720,m_lfit",
                  });
                }
                ro._specs = JSON.parse(ro.specs);
                let ids = [];
                ro._specs.forEach((s) => {
                  let o;
                  if (!specObj.hasOwnProperty(s.name)) {
                    o = {
                      index: specArr.length,
                      name: s.name,
                      expand: false,
                      values: [{ name: s.value, count: 0 }],
                      current: -1,
                    };
                    specObj[s.name] = o;
                    specArr.push(o);
                    ids[o.index] = 0;
                  } else {
                    o = specObj[s.name];
                    let inx = o.values.findIndex((sub) => {
                      return sub.name === s.value;
                    });
                    if (inx < 0) {
                      o.values.push({ name: s.value, count: 0 });
                      ids[o.index] = o.values.length - 1;
                    } else {
                      ids[o.index] = inx;
                    }
                  }
                });
                ro._sid = ids.join("-") + "-";
                skuIds.push(ro._sid);
                // }
              } catch (e) {
                console.log(e);
              }
            });
          this.specArray = specArr;
          this.specObect = specObj;
          this.skus = res;
          this.skuIds = skuIds;
          if (this.specArray.length > 0) {
            window.mall.history.add({
              id: this.detail.id,
              coverImg: this.detail.coverImg,
              name: this.detail.name,
              price: this.detail.price,
              supplier: this.supplier,
            });
            this.selectSpec();
          }
        })
        .finally((_) => {
          this.skuLoading = false;
          this.loadImages();
        });
    },
    loadPrice() {
      if (this.sku && this.sku.id && !isNaN(this.nums)) {
        this.priceInfo.loading = true;
        this.priceInfo.error = false;
        this.priceInfo.oldValue = null;
        this.priceInfo.value = null;
        getPurchaseGoodsPrice({
          goodsId: this.sku.id,
          quantity: this.nums,
          sellerId: this.supplier,
        })
          .then((res) => {
            let v = res.goodsPrice;
            if (typeof v !== "number") {
              this.priceInfo.error = "没有价格";
            } else {
              this.priceInfo.oldValue = res.goodsPrice;
              this.priceInfo.value = v < 0 ? 0 : v;
            }
          })
          .catch((err) => {
            this.priceInfo.error = "获取失败";
          })
          .finally((_) => {
            this.priceInfo.loading = false;
          });
      }
    },
    loadBom() {
      if (this.sku) {
        getBom(this.sku.id).then((res) => {
          this.boms = res;
        });
      } else {
        this.boms = [];
      }
    },
    loadGroupSales() {
      if (this.sku) {
        request({
          url: "api/promotion/groupSale",
          method: "get",
          params: {
            sellerId: this.supplier,
            goodsId: this.sku.id,
          },
        }).then(
          (res) => {
            this.groupSales = res.content;
          },
          (err) => {
            this.groupSales = [];
          }
        );
      }
    },
    loadPromotions() {
      if (this.sku) {
        request({
          url: "api/promotion/available",
          method: "get",
          params: {
            sellerId: this.supplier,
            goodsId: this.sku.id,
          },
        }).then(
          (res) => {
            this.promotions = res;
          },
          (err) => {
            this.promotions = [];
          }
        );
      }
    },
    loadBuyLimit() {
      if (this.isMallPromotionProduct === true) {
        this.buyEnable = true;
      } else {
        request({
          url: "api/buyLimit",
          method: "get",
          params: {
            sellerId: this.supplier,
          },
        }).then((res) => {
          this.buyEnable = res.allow;
        });
      }
    },
    selectSpec(rowIndex = 0, valIndex = -1) {
      let ids = [];
      for (let i = 0; i <= rowIndex - 1; i++) {
        ids.push(this.specArray[i].current);
      }
      if (rowIndex < this.specArray.length) {
        for (
          let i = 0, l = this.specArray[rowIndex].values.length;
          i < l;
          i++
        ) {
          let prefix = [].concat(ids).concat([i]).join("-") + "-";
          let count = this.skus.filter((s) => {
            return s._sid && s._sid.indexOf(prefix) === 0;
          }).length;
          this.specArray[rowIndex].values[i].count = count;
          if (valIndex < 0 && count > 0) {
            valIndex = i;
            this.specArray[rowIndex].current = valIndex;
          } else if (valIndex === i) {
            if (count > 0) {
              this.specArray[rowIndex].current = valIndex;
            } else {
              valIndex = -1;
              this.specArray[rowIndex].current = -1;
            }
          }
        }
        if (rowIndex < this.specArray.length - 1) {
          this.specArray[rowIndex + 1].current = -1;
          this.selectSpec(rowIndex + 1, this.specArray[rowIndex + 1].current);
        } else {
          let skuId = ids.join("-") + (ids.length ? "-" : "") + valIndex + "-";
          let sku = this.skus.find((s) => {
            return s._sid === skuId;
          });
          if (sku) {
            if (this.sku && this.sku.id === sku.id) return;
            if (sku.coverImg) {
              let inx = this.images.findIndex((o) => {
                return o.url === sku.coverImg;
              });
              if (inx < 0) inx = 0;
              this.imageIndex = inx;
            }
            if (sku.prices && sku.prices.length) {
              let mq = null;
              sku.prices.forEach((p) => {
                if (!mq || mq > p.modMin) mq = p.modMin;
              });
              sku.minSalesQuantity = mq;
              if (mq > this.nums) this.nums = mq;
            }
            this.sku = sku;
            this.loadPrice();
            this.loadBom();
            this.loadGroupSales();
            this.loadPromotions();
          }
        }
      }
    },
    generateGoodsItem(includePrice = false) {
      if (this.sku) {
        let o = {
          _id: this.$uuid(),
          goodsId: this.sku.id,
          goodsSpec: this.sku.specs,
          goodsName: this.sku.name,
          minSalesQuantity: this.sku.minSalesQuantity || 0,
          coverImg: this.sku.coverImg || this.detail.coverImg,
          code: this.sku.code,
          erpCode: this.sku.erpCode,
          groupId: this.$uuid(),
          deliveryCycle: this.sku.deliveryCycle || 7,
          count: this.nums || this.sku.minSalesQuantity || 1,
          info: "",
          discountAmount: 0,
          giftDiscountPrice: 0,
          giftDiscountAmount: 0,
          purchaseLimit: this.sku.purchaseLimit,
          promotionId: null,
          promotionJoinType: "",
          promotionMutex: false,
          wholePromotionId: null,
          wholePromotionName: null,
          wholeGroupId: null,
          singleGoodsGroupId: null,
          singleGoodsLadderId: null,
          groupSaleId: null,
          groupSaleItemGoodsId: null,
          groupSaleName: null,
          groupSaleRule: null,
        };
        if (includePrice) {
          o._price = this.priceInfo;
        }
        return o;
      }
      return null;
    },
    handleCartAdd() {
      let item = this.generateGoodsItem();
      if (item) {
        window.mall.cart.add("goods", item);
      }
    },
    handleNumsChange(val) {
      if (!val) {
        let n = this.sku ? this.sku.minSalesQuantity : 1;
        this.$nextTick((_) => {
          this.nums = n;
        });
      } else {
        this.loadPrice();
      }
    },
    handleBuy() {
      let item = this.generateGoodsItem(true);
      if (item) {
        window.localStorage.setItem(
          "cart-confirm",
          JSON.stringify({ goods: [item], groups: [] })
        );
        this.$router.push({
          path: "/mall/cart/confirm",
          query: {
            mode: "1",
          },
        });
      }
    },
  },
  activated() {
    this.detail = null;
    this.skus = [];
    this.sku = null;
    this.images = [];
    this.imageIndex = 0;
    this.nums = 1;
    if (this.$route.params.id) {
      let idp = atob(this.$route.params.id).split("_&_");
      if (idp && idp.length) {
        this.spuId = idp[0];
        this.isMallPromotionProduct = idp.length > 1 && idp[1] === "mpp";
        this.loadDetail();
        this.loadProperty();
        this.loadBuyLimit();
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadDetail();
    this.loadProperty();
  },
};
</script>